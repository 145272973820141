<script setup>
import { useNuxtApp } from '#imports';
import BackgroundBlock from '@/components/blocks/BackgroundBlock.vue';

const { $tp } = useNuxtApp();
</script>
<template>
  <div class="index-page">
    <BackgroundBlock target-class="index-page" class="index-page__bg" />

    <div class="index-page__body">
      <div class="index-page__content">
        <div class="index-page__heading">
          <h2 class="index-page__font index-page__font--heading">
            {{ $tp('Скоро здесь будет новый сайт бренда Winston') }}
          </h2>
        </div>

        <div class="index-page__description">
          <p class="index-page__font index-page__font--description">
            Мы обновляем сайт, чтобы<br />
            предоставить вам новые возможности
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.index-page {
  display: flex;
  padding: 0 em(30);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  &__font {
    &--heading {
      @include h2;

      color: $color-white;

      @include media-breakpoint-down(lg) {
        font-size: em(36);
      }

      @include media-breakpoint-down(sm) {
        @include h2-mob;
      }
    }

    &--description {
      @include subtitle-m;

      color: $color-white;

      @include media-breakpoint-down(lg) {
        font-size: em(10);
      }

      @include media-breakpoint-down(sm) {
        @include subtitle-m-mob;
      }

      &:deep(strong) {
        font-weight: 700;
      }
    }
  }

  &__body {
    display: flex;
    margin: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: em(704);
    text-align: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__description {
    max-width: em(496);
    margin-top: em(32);

    @include media-breakpoint-down(sm) {
      margin-top: em(16);
    }
  }
}
</style>
